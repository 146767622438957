import { EnvironmentData } from '@cf/core';

export const environment: EnvironmentData = {
  production: true,
  enabledNamespaces: [],
  apiUrl: 'https://api.stg.calfrenzy.bitstudios.dev/',
  appUrl: 'https://stg.calfrenzy.bitstudios.dev',
  publicUrl: 'https://stg.calfrenzy.bitstudios.dev/',
  stripeId:
    'pk_test_51Pv0E5Kj2WT4T8Fnv0NuFk76rz3saWwejgTK69skfeObAfq8vDsrTOPKuxlTvS3VbgI9DovMd7ktATJrI3MfdyYa00AlptQRix',
  domain: '',
  envName: 'stg',
  googleClientId:
    '369573383535-phvu70n2klaevh7vh1s0u3pjrkgl5goc.apps.googleusercontent.com',
  googleMapId: 'AIzaSyCMQC8InSaMfLqxtPGumLRWGEQTD8gHuhs',
  zoomClientId: 'BvATuJS_RJGIzYSNBxQKA',
  buildVersion: 'abf8b5695c5d3c0402f1fa6465ed1849743b0b5a',
};
